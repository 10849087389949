import React, { useState } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "./Layout"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import { AdvancedImage } from "@cloudinary/react"
import { seoHead, isSafariLess16 } from "../components/utils.js"
import * as style from "./page-template-clients.module.css"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        cfClientsParent {
          clientsParentTheTitle
          clientsParentTheClients {
            clientsParentTheIndustry
            clientsParentAClient {
              ... on WPGraphQL_Page {
                id
                title
                uri
                cfClientsSingleAll {
                  singleClientHeroIntro
                  clientGlobalSharedImage {
                    altText
                    mediaDetails {
                      file
                    }
                    mimeType
                  }
                }
              }
            }
          }
        }

        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }
    }
  }
`
const PageTemplateClients = ({ data }) => {
  const page = data.wpgraphql.page
  const theClients = page.cfClientsParent.clientsParentTheClients

  const [fintechVisible, setFintechVisible] = useState(true)
  const [healthtechVisible, setHealthtechVisible] = useState(true)
  const [cleantechVisible, setCleantechVisible] = useState(true)
  const [currentItem, setCurrentItem] = useState("showAll")

  const showAll = () => {
    setFintechVisible(true)
    setHealthtechVisible(true)
    setCleantechVisible(true)
    setCurrentItem("showAll")
  }

  const showFintech = () => {
    setFintechVisible(true)
    setHealthtechVisible(false)
    setCleantechVisible(false)
    setCurrentItem("showFintech")
  }

  const showHealthtech = () => {
    setFintechVisible(false)
    setHealthtechVisible(true)
    setCleantechVisible(false)
    setCurrentItem("showHealthtech")
  }

  const showCleantech = () => {
    setFintechVisible(false)
    setHealthtechVisible(false)
    setCleantechVisible(true)
    setCurrentItem("showCleantech")
  }

  const showMisc = () => {
    setFintechVisible(false)
    setHealthtechVisible(false)
    setCleantechVisible(false)
    setCurrentItem("showMisc")
  }

  // cloudinary
  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  const isSafariV16 = isSafariLess16()

  return (
    <>
      <Layout>
        <div className="darkgrey_bg mb-6 pt-6">
          <div className="container">
            <div id="hide-row-when-nav-open" className="row">
              <div className="col-12 col-lg-7 mt-5 color-white animated-headline">
                <div className="animated-headline-wrap">
                  <h1 className="lower new_style">
                    <span
                      className={`new_style_addit ${
                        isSafariV16 ? "safari_16" : ""
                      }`}
                    >
                      {page.cfClientsParent.clientsParentTheTitle}
                    </span>
                  </h1>
                </div>
              </div>
              <div className="col-12 mt-6 header-fade-in">
                <ul
                  className={`${style.options} list-unstyled d-flex flex-wrap`}
                >
                  <li
                    className={
                      currentItem === "showAll"
                        ? "me-5 mb-3 current_item ani-underline"
                        : "me-5 mb-3"
                    }
                    onClick={showAll}
                  >
                    All Industries
                  </li>
                  <li
                    className={
                      currentItem === "showFintech"
                        ? "me-5 mb-3 current_item ani-underline"
                        : "me-5 mb-3"
                    }
                    onClick={showFintech}
                  >
                    Financial Services
                  </li>
                  <li
                    className={
                      currentItem === "showHealthtech"
                        ? "me-5 mb-3 current_item ani-underline"
                        : "me-5 mb-3"
                    }
                    onClick={showHealthtech}
                  >
                    Health
                  </li>
                  <li
                    className={
                      currentItem === "showCleantech"
                        ? "me-5 mb-3 current_item ani-underline"
                        : "me-5 mb-3"
                    }
                    onClick={showCleantech}
                  >
                    Energy
                  </li>
                  <li
                    className={
                      currentItem === "showMisc"
                        ? "me-5 mb-3 current_item ani-underline"
                        : "me-5 mb-3 d-none"
                    }
                    onClick={showMisc}
                  >
                    Misc
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-6">
          <div className="row gx-5">
            {theClients.map(client => {
              let uClient = client.clientsParentAClient
              let indType = client.clientsParentTheIndustry

              let imgUri =
                client.clientsParentAClient.cfClientsSingleAll
                  .clientGlobalSharedImage.mediaDetails.file
              let theAlt =
                client.clientsParentAClient.cfClientsSingleAll
                  .clientGlobalSharedImage.altText

              let theImage = cld.image(
                `${process.env.GATSBY_API_FOLDER_URL}${imgUri}`
              )

              theImage.resize(fill().width(1152).height(668))
              theImage.format("auto")

              let theOutput = () => {
                return (
                  <>
                    <Link to={uClient.uri} title={uClient.title}>
                      <AdvancedImage
                        cldImg={theImage}
                        alt={theAlt}
                        className="w-100"
                      />
                      <p className="mt-3 mb-2 color-light-grey">
                        {uClient.title}
                      </p>
                    </Link>
                    <Link to={uClient.uri} title={uClient.title}>
                      <h3 className="lower">
                        {uClient.cfClientsSingleAll.singleClientHeroIntro}
                      </h3>
                    </Link>
                  </>
                )
              }

              if (indType === "HealthTech") {
                return (
                  <div
                    key={uClient.id}
                    className={
                      healthtechVisible
                        ? `${style.showItem} col-12 col-lg-6`
                        : `${style.hideItem} col-12 col-lg-6`
                    }
                  >
                    {theOutput()}
                  </div>
                )
              } else if (indType === "FinTech") {
                return (
                  <div
                    key={uClient.id}
                    className={
                      fintechVisible
                        ? `${style.showItem} col-12 col-lg-6`
                        : `${style.hideItem} col-12 col-lg-6`
                    }
                  >
                    {theOutput()}
                  </div>
                )
              } else if (indType === "CleanTech") {
                return (
                  <div
                    key={uClient.id}
                    className={
                      cleantechVisible
                        ? `${style.showItem} col-12 col-lg-6`
                        : `${style.hideItem} col-12 col-lg-6`
                    }
                  >
                    {theOutput()}
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PageTemplateClients

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
